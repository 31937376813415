// frontend/src/redux/reducer.js
import { SET_ACTIVE_TAB, SET_USER } from './actionTypes';

const initialState = {
  activeTab: 'home',
  user: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
