// src/components/Leaderboard.js
import React from 'react';
import './Leaderboard.css';

function Leaderboard() {
  return (
    <div className="leaderboard-content">
      <h1>Leaderboard</h1>
      {/* Leaderboard content goes here */}
    </div>
  );
}

export default Leaderboard;
