// frontend/src/App.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from './components/Navigation';
import Home from './components/Home';
import Leaderboard from './components/Leaderboard';
import More from './components/More';
import { setUser } from './redux/actions';
import axios from 'axios';
import './App.css';

function App() {
  const activeTab = useSelector((state) => state.activeTab);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch user data
    axios
      .get('/auth/user', { withCredentials: true })
      .then((response) => {
        dispatch(setUser(response.data.user));
      })
      .catch((error) => {
        console.log('Error fetching user:', error);
      });
  }, [dispatch]);

  const renderContent = () => {
    switch (activeTab) {
      case 'home':
        return <Home />;
      case 'leaderboard':
        return <Leaderboard />;
      case 'more':
        return <More />;
      default:
        return <Home />;
    }
  };

  return (
    <div className="container">
      {renderContent()}
      <Navigation />
    </div>
  );
}

export default App;
