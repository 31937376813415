// src/components/More.js
import React from 'react';
import './More.css';

function More() {
  return (
    <div className="more-content">
      <h1>More Options</h1>
      {/* More options content goes here */}
    </div>
  );
}

export default More;
