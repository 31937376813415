// src/components/Navigation.js
import React from 'react';
import { useDispatch } from 'react-redux';
import { setActiveTab } from '../redux/actions';
import './Navigation.css';

function Navigation() {
  const dispatch = useDispatch();

  const handleTabClick = (tab) => {
    dispatch(setActiveTab(tab));
  };

  return (
    <div className="navigation">
      <div className="nav-item" onClick={() => handleTabClick('home')}>
        <button className="nav-button">
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.8913288,10 L11.8900003,3.99867157 L5.88867192,10 L5.89001465,10 L5.89001465,20 L17.8900146,20 L17.8900146,10 L17.8913288,10 Z M19.8900146,11.9986859 L19.8900146,20 C19.8900146,21.1045695 18.9945841,22 17.8900146,22 L5.89001465,22 C4.78544515,22 3.89001465,21.1045695 3.89001465,20 L3.89001465,11.9986573 L2.41319817,13.4754737 L1,12.0622756 L10.4769858,2.5852898 C11.2573722,1.8049034 12.5226285,1.8049034 13.3030149,2.5852898 L22.7800007,12.0622756 L21.3668025,13.4754737 L19.8900146,11.9986859 Z" fill="white" fill-rule="evenodd"/>
                    </svg>
        </button>
        <span className="nav-label">Home</span>
      </div>
      <div className="nav-item" onClick={() => handleTabClick('leaderboard')}>
        <button className="nav-button">
        <svg height="24" width="24" id="Icons" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g id="Icons-Bank_cards" data-name="Icons-Bank cards">
                            <g id="Icons_bank_cards">
                                <rect height="10" width="4.667" x="16.333" y="9" fill="white"/>
                                <rect height="14" width="4.667" x="9.667" y="5" fill="white"/>
                                <rect height="6" width="4.667" x="3" y="13" fill="white"/>
                            </g>
                        </g>
                    </svg>
        </button>
        <span className="nav-label">Leaderboard</span>
      </div>
      <div className="nav-item" onClick={() => handleTabClick('more')}>
        <button className="nav-button">
        <svg height="24" width="24" id="Layer_1_1_" version="1.1" viewBox="0 0 16 16">
                        <circle cx="2" cy="8" r="2" fill="white"/>
                        <circle cx="8" cy="8" r="2" fill="white"/>
                        <circle cx="14" cy="8" r="2" fill="white"/>
                    </svg>
        </button>
        <span className="nav-label">More</span>
      </div>
    </div>
  );
}

export default Navigation;
