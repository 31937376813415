// frontend/src/redux/actions.js
import { SET_ACTIVE_TAB, SET_USER } from './actionTypes';

export const setActiveTab = (tab) => ({
  type: SET_ACTIVE_TAB,
  payload: tab,
});

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});
